 <template>
  <article class="indexes">
    <ul class="indexes__list">
      <template v-for="(indx, index) of indexes">
        <li v-if="indx.value" class="indexes__list-item" :key="index">
          <span class="text">
            {{ indx.title }}<sub class="sub">{{ indx.subtitle }}</sub>
          </span>
          <span class="value">{{ indx.value }}</span>
        </li>
      </template>
    </ul>
    <Recap :indexes="sectorIndexes" v-if="isResult" />
  </article>
</template>

<script>
import Recap from '@/components/dump/RecapComponent'

export default {
  name: 'Index',
  components: { Recap },
  props: ['sectorIndexes', 'isResult'],
  computed: {
    isRwTested() {
      return this.sectorIndexes.isTested
    },
    indexes() {
      const indexes = [
        {
          label: 'rw',
          type: 'required',
          title: this.$t('message.constructionParams.indexes.rw'),
          subtitle: this.$t('message.constructionParams.indexes.subs.rw'),
          value: null
        },
        {
          label: 'rw',
          type: 'calculated',
          title: this.$t(`message.constructionParams.indexes.calculated.${this.isRwTested ? 'rw-tested' : 'rw'}`),
          subtitle: this.$t(`message.constructionParams.indexes.calculated.subs.${this.isRwTested ? 'rw-tested' : 'rw'}`),
          value: null
        },
        {
          label: 'lnw',
          type: 'required',
          title: this.$t('message.constructionParams.indexes.lnw'),
          subtitle: this.$t('message.constructionParams.indexes.subs.lnw'),
          value: null
        },
        {
          label: 'lnw',
          type: 'calculated',
          title: this.$t('message.constructionParams.indexes.calculated.lnw'),
          subtitle: this.$t('message.constructionParams.indexes.calculated.subs.lnw'),
          value: null
        }
      ]

      Object.entries(this.sectorIndexes)
        .filter(([type, value]) => value !== null)
        .forEach(([type, value]) => {
          indexes.forEach((index) => {
            if (index.type === type && value[index.label]) {
              index.subtitle = index.subtitle.replace(' ', '\u00A0')
              index.value = `${value[index.label]} ${this.$t('message.units.dB')}`
            }
          })
        })

      return this.isResult ? indexes : indexes.filter(item => item.type === 'required')
    }
  }
}
</script>

<style scoped lang="sass">
.indexes
  width: 100%
  margin: 0 0 rem(30)
  &__list
    display: flex
    gap: 20px
    +media((flex-wrap: (0: wrap, 1000: nowrap)))
    &-item
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      gap: rem(10)
      padding: rem(18) rem(20)
      @extend %18
      line-height: 1.3
      color: $text-gray
      border-radius: $radius
      background: $grey-fill
      .text
        flex-grow: 1
        margin: 0
        text-align: left
      .value
        flex-shrink: 0
        color: $main-text
</style>
